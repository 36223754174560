import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { css, Theme, useTheme } from '@emotion/react';

import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';
import SubDropdownLink from '@/components/organisms/Navigation/SubDropdown';

import useOnClickOutside from '@/hooks/useOutsideClick';

import mq from '@/styles/mq';

import LinkDataType from '@/types/data/link';
import LogoGuilmin from '@/svg/GuilminLogoFinal.svg';

interface NavLinkProps {
  invert?: boolean;
  isScrolled?: boolean;
  link?: LinkDataType;
}

interface Props {
  invert?: boolean;
  links: LinkDataType[];
  show?: boolean;
  contact: LinkDataType;
}

const NavRoot = styled('div')`
  display: none;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.zIndex.nav};
  transition: all 0.3s ease-out;

  ${mq('lg')} {
    display: block;
  }
`;
const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  transform: translateY(${({ theme }) => theme.spacing(2.5)});
`;
const Logo = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(6)}; ;
`;
const LogoLink = styled(Link)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.8)};
  margin-right: ${({ theme }) => theme.spacing(6)};
  svg {
    width: 190px;
    height: auto;
  }
  /* .st0 {
    fill: coral !important;
  } */
`;
const LinksContainer = styled('nav')``;
const NavList = styled('ul')`
  display: flex;
  align-items: center;
`;
const NavLinkRoot = styled(Link)`
  color: ${({ theme }) => theme.color.white.main};
  margin-right: ${({ theme }) => theme.spacing(5.5)};
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.color.white.main};
  }

  &:after {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.color.white.main};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:after {
    opacity: 1;
  }
`;
const NavButton = styled(Button)`
  margin-left: auto;
  transition: all 0.3s ease-out;
  font-size: 1.7rem;
`;
const DropdownRoot = styled('div')`
  position: relative;
`;
const DropdownLinkMain = styled('li')`
  color: ${({ theme }) => theme.color.white.main};
  margin-right: ${({ theme }) => theme.spacing(7.5)};
  position: relative;
  cursor: pointer;
`;
const DropdownSubRoot = styled(Grid)`
  position: absolute;
  bottom: -10px;
  left: -25%;
  transform: translateY(100%) scale(0.7);
  background-color: ${({ theme }) => theme.color.white.main};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  box-shadow: 0 7px 14px 2px ${({ theme }) => theme.color.textSecondary.dark};
  border-radius: 10px;
  transition: all 0.3s ease;
  display: none;
  opacity: 0;
`;
const DropdownLinkSub = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  position: relative;

  &:after {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.color.primary.main};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:after {
    opacity: 1;
  }
`;
const AddBackground = (theme: Theme) => css`
  background-color: ${theme.color.primary.main};
  transition: all 0.2s ease-out;
`;
const RemoveBackground = (theme: Theme) => css`
  background-color: transparent !important;
  transition: all 0.2s ease-out;
`;
const TranslateNav = (theme: Theme) => css`
  transform: translateY(${theme.spacing(1)});
  transition: all 0.2s ease-out;
`;
const TranslateNavBack = (theme: Theme) => css`
  transform: translateY(${theme.spacing(2)});
  transition: all 0.2s ease-out;
`;
const InvertNavLinks = (theme: Theme) => css`
  color: ${theme.color.primary.main};

  &:hover {
    color: ${theme.color.primary.main};
  }

  &:hover:after {
    background-color: ${theme.color.primary.main};
  }
`;
const NavScrolledLink = (theme: Theme) => css`
  color: ${theme.color.white.main};
`;
const ShowDropdown = css`
  display: flex;
  opacity: 1;
  transform: translateY(100%) scale(1);
`;

const NavLink = ({ link, invert, isScrolled }: NavLinkProps) => {
  const { path, label } = link || {};

  return (
    <li>
      {path && (
        <NavLinkRoot
          css={[
            // @ts-ignore
            invert && !isScrolled && InvertNavLinks,
            // @ts-ignore
            invert && isScrolled && NavScrolledLink,
          ]}
          to={path}
        >
          {label}
        </NavLinkRoot>
      )}
    </li>
  );
};

const DropdownLink = ({
  link,
  invert,
  isScrolled,
}: NavLinkProps): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const dropdownBoxRef = useRef(null);

  const { label, dropdown } = link || {};

  useOnClickOutside(dropdownBoxRef, () => setOpenDropdown(false));

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  return (
    <DropdownRoot ref={dropdownBoxRef}>
      {/* Clickable main link */}
      <DropdownLinkMain
        css={[
          // @ts-ignore
          invert && !isScrolled && InvertNavLinks,
          // @ts-ignore
          invert && isScrolled && NavScrolledLink,
        ]}
        onClick={handleOpenDropdown}
      >
        {label}
      </DropdownLinkMain>
      {/* Dropdown box */}
      <DropdownSubRoot
        container
        flexDirection="column"
        alignItems="center"
        css={openDropdown && ShowDropdown}
      >
        {/* Sub links */}
        {/* {dropdown?.map(
          (drop, i) =>
            drop.path && (
              <DropdownLinkSub key={i.toString()} to={drop.path}>
                {drop.label}
              </DropdownLinkSub>
            ),
        )} */}
        {dropdown?.map((drop, i) => {
          if (drop.dropdown) {
            return (
              <>
                <SubDropdownLink
                  key={i.toString()}
                  invert={invert}
                  isScrolled={isScrolled}
                  link={drop}
                />
              </>
            );
          }
          return (
            drop.path && (
              <DropdownLinkSub key={i.toString()} to={drop.path}>
                {drop.label}
              </DropdownLinkSub>
            )
          );
        })}
      </DropdownSubRoot>
    </DropdownRoot>
  );
};

const DesktopNav = ({ invert, links, show, contact }: Props): JSX.Element => {
  const navRef = useRef<HTMLDivElement>(null);
  const [navHeight, setNavHeight] = useState<number>(0);
  const [navScrolled, setNavScrolled] = useState<boolean>(false);
  const { color } = useTheme();

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -navHeight) {
        setNavScrolled(true);
      } else if (currPos.y > -navHeight) {
        setNavScrolled(false);
      }
    },
    [navHeight],
    // undefined,
    // false,
    // 500,
  );

  useEffect(() => {
    if (navRef.current) {
      setNavHeight(navRef.current.getBoundingClientRect().height);
    }
  }, []);

  const Links = links.map((link, index) => {
    if (link.dropdown) {
      return (
        <DropdownLink
          key={index.toString()}
          invert={invert}
          isScrolled={navScrolled}
          link={link}
        />
      );
    }

    return (
      <NavLink
        key={index.toString()}
        invert={invert}
        isScrolled={navScrolled}
        link={link}
      />
    );
  });

  return (
    <NavRoot ref={navRef} css={navScrolled ? AddBackground : RemoveBackground}>
      <NavContainer
        maxWidth="lg"
        css={navScrolled ? TranslateNav : TranslateNavBack}
      >
        <LogoLink to="/">
          <LogoGuilmin
            css={
              invert && !navScrolled
                ? css`
                    .st0 {
                      fill: ${color.primary.main} !important;
                    }
                  `
                : css`
                    .st0 {
                      fill: white !important;
                    }
                  `
            }
          />
          {/* <Logo
            id="Logo"
            variant="h3"
            color={invert && !navScrolled ? colorLogo : 'white'}
          >
            Guilmin
          </Logo> */}
        </LogoLink>
        {show && (
          <LinksContainer>
            <NavList>{Links}</NavList>
          </LinksContainer>
        )}
        <NavButton
          size="sm"
          variant={navScrolled ? 'outlined' : 'contained'}
          color={navScrolled ? 'white' : 'primary'}
          as={Link}
          {...{ to: `${contact?.path}` }}
        >
          {contact?.label}
        </NavButton>
      </NavContainer>
    </NavRoot>
  );
};

export default DesktopNav;
